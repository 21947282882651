<template>
    <div :style='`background-image:url(${BackgroundImage})`' class="login tw-bg-gray-200">        
        <Toast position="bottom-right"/>  
        <div class='tw-w-full tw-h-screen tw-overflow-hidden'>
            <div class='sm:tw-max-w-sm  tw-w-full  tw-ml-auto lg:tw-mr-32  tw-h-full tw-flex tw-justify-center  tw-items-center tw-px-2'>
                    <Card class='p-shadow-24 tw-bg-gray-50 tw-p-4 tw-pb-0 tw-pt-0 tw-w-full tw-mt-10 ' :class='{"animacion": animated, "animate" : !animated }' >
                        <!-- <template #header>
                            <img class=' tw-m-auto p-shadow-3 tw-my-2 tw-w-1/3! tw-rounded' style="fill-style:cover;" alt="Logo Clinica" :src="logo">
                        </template> -->
                        <template #title >
                            <h2 class='tw-py-4'>Iniciar Sesion</h2>
                            <hr>
                        </template>
                        <template #content>
                            <div  class='p-fluid tw-space-y-4'>
                                <div class='p-inputgroup user-group'>
                                    <Dropdown class='' v-model="tipoDni" maxlength="10" :options="tiposDNI"/>
                                    <InputText @keyup.enter='handleClick' @input='handleChangeUser' placeholder='Numero de documento' v-model="user" />
                                </div>
                                <div class='p-field'>
                                    <Password @keyup.enter='handleClick' placeholder='Contraseña' toggleMask :feedback="false" v-model="pass" />
                                </div>
                                <span class='tw-w-full tw-text-sm tw-text-red-500'>{{error}}</span>
                            </div>
                        </template>
                        <template #footer >
                            <div class='tw-space-y-2'>
                                <Button :loading='isLoad' label="Ingresar" class='tw-w-full ' @click="handleClick"/>
                                <Button label="Olvide mi contraseña" class="p-button-link" @click="handlePassRecoverClick(true)" />
                                <hr>
                                <Button label='Nuevo Usuario' class='tw-w-full p-button-outlined p-button-info' @click="handleNuevoUsuarioClick(true)" />
                            </div>
                            <div class='tw-mt-4 tw-flex tw-items-center tw-justify-center'>
                                <a href="https://globalapp.ar" target="_blank">
                                    <img alt='logo' src='../assets/LogoWeb.png' style='height:2.5em;'/>
                                </a>
                            </div>
                        </template>
                    </Card>
            </div>
        </div>
    </div>

    <!-- 

        Nuevo Usuario

     -->
    <Dialog @hide='handleNuevoUsuarioClick(false)' v-model:visible="ModalDisplay" :modal="true" :style="{'max-width': '100vw'}" class='tw-rounded-lg!'>
            <template #header>
                <div class='tw-w-full'>
                    
                </div>
            </template>



            <div v-if='!isModoEsperarCodigo'>
                <div v-if='!isModoRegistro' class='tw-w-full'>
                    <span class='tw-text-md'><strong>Requisitos:</strong></span><br><br>
                    Haberse atendido con anterioridad.<br><br>

                    Ingrese con su Tipo y Número de Documento.
                    Su contraseña temporal será su año de Nacimiento (ej: 1985).
                    
                </div>

                <div class='tw-w-full' v-else>

                    <div class='tw-w-full tw-space-y-4'>

                        <div class='p-inputgroup tw-space-x-2'>
                            <InputText placeholder='Nombre' v-model="nuevoUserNombre" />
                            <InputText placeholder='Apellido' v-model="nuevoUserApellido" />
                        </div>
                        
                        <div class='p-inputgroup user-group'>
                            <Dropdown class='dropdown' v-model="tipoDni" maxlength="10" :options="tiposDNI"/>
                            <InputText placeholder='Numero de documento' v-model="nuevoUserDocumento" />
                        </div>

                        <div class='p-inputgroup tw-space-x-2'>
                            <Calendar placeholder='Fecha de nacimiento' v-model="nuevoUserFechaNacimiento" />
                            <Dropdown v-model='nuevoUserSexo' maxlength="10" :options="['Masculino','Femenino']" placeholder='Sexo' />
                        </div>


                        <div class='p-inputgroup'>
                            <InputText placeholder='Email' type='email' v-model="nuevoUserEmail" />
                        </div>
                        
                        

                        <div class='p-inputgroup'>
                            <InputText placeholder='Direccion' v-model="nuevoUserDireccion" />
                        </div>

                        <div class='p-inputgroup'>
                            <InputText placeholder='Telefono' type='tel' v-model="nuevoUserTelefono" />
                        </div>

                        <div class='p-inputgroup'>
                            <Dropdown optionLabel='nombre' :options="coberturas" @change='onCoberturaChanged' v-model='nuevoUserCobertura' placeholder='Cobertura'/>
                        </div>

                        <div class='p-inputgroup' v-if='nuevoUserCobertura && nuevoUserCobertura.nombre.toLowerCase() !== "particular"' >
                            <Dropdown @change='onPlanesChanged' :options="planesCoberturas" optionLabel='nombre' v-model='nuevoUserPlan' placeholder='Plan'/>
                        </div>

                        <div class='p-inputgroup' v-if='nuevoUserCobertura && nuevoUserCobertura.nombre.toLowerCase() !== "particular"' >
                            <Dropdown :options="planesCondicionIva" optionLabel='label' v-model='nuevoUserCondicionIva' placeholder='Condicion Iva'/>
                        </div>

                        <div v-if='nuevoUserCobertura && nuevoUserCobertura.nombre.toLowerCase() !== "particular"' class='p-inputgroup'>
                            <InputText placeholder='Credencial' v-model="nuevoUserCarnetCobertura" />
                        </div>

                        <div class='tw-w-full tw-text-center'>
                            <span class='tw-text-red-500 tw-text-sm'>{{errorNuevoUsuario}}</span>    
                        </div>




                        
                    </div>



                </div>
            </div>

            <div v-else>



                    <div class='tw-text-center tw-w-full!'>
                        <span>Se ha enviado un código a la direccion de correo: {{nuevoUserEmail}} </span>
                    </div>

                    <div class='tw-w-full! tw-flex  tw-justify-center tw-items-center tw-py-4 tw-space-x-2'>
                        <InputText size='large' placeholder='Ingrese el codigo.' v-model='codigoDeRecuperacion' />
                    </div>

                    <div class='tw-text-center'>
                        <span v-if='time.asSeconds() > 0'>Volver a enviar en: <span>{{`${time.minutes()}`.padStart(2,'00')}}:{{`${time.seconds()}`.padStart(2,'00')}}</span></span>
                        <Button v-else label='Reenviar Codigo' class='p-button-primary p-button-link tw-px-8' @click="onAceptarClick()"/>
                    </div>

                    <div class='tw-w-full'>
                        <span class='tw-text-red-500 tw-text-sm'>{{errorNuevoUsuario}}</span>
                    </div>


                
            </div>

            

            <template #footer>
                <Button v-if='!isModoRegistro && !isModoEsperarCodigo' label='Registro' class='p-button-outlined' @click='isModoRegistro = true' />  
                <Button v-else label='Aceptar' @click='() => isModoEsperarCodigo ? onAceptarCodigoClick() : onAceptarClick()' class='p-button-outlined' />  
                <Button label='Salir' class='p-button-primary tw-px-8' @click="handleNuevoUsuarioClick(false)"/>
            </template>
        
    </Dialog>

    <!-- 

        Recuperar Contraseña

     -->
    <Dialog @hide='handlePassRecoverClick(false)' v-model:visible="ModalPassRecover" :modal="true" :style='{"max-width":"100vw"}'>
            <template #header >
                <div class='tw-w-full'>
                    
                </div>
            </template>

            
            <div class='tw-w-full tw-pb-4'>
                <span><strong>Recuperacion de contraseña</strong></span>
            </div>

            <div v-if='!isCodeValid' class='tw-w-full! tw-p-4'>

                <div class='tw-text-center tw-w-full!'>
                    <span>Se ha enviado un código a la direccion de correo: {{emailDeRecuperacion}} </span>
                </div>

                <div class='tw-w-full! tw-flex  tw-justify-center tw-items-center tw-py-4 tw-space-x-2'>
                    <InputText size='large' placeholder='Ingrese el codigo.' v-model='codigoDeRecuperacion' />
                </div>

                <div class='tw-text-center'>
                    <span v-if='time.asSeconds() > 0'>Volver a enviar en: <span>{{`${time.minutes()}`.padStart(2,'00')}}:{{`${time.seconds()}`.padStart(2,'00')}}</span></span>
                    <Button v-else label='Reenviar Codigo' class='p-button-primary p-button-link tw-px-8' @click="handlePassRecoverClick(true)"/>
                </div>

            </div>

            <div v-else class='tw-w-full! tw-flex tw-flex-col tw-justify-center tw-items-center tw-space-y-2 tw-p-4'>
                <div class='tw-flex tw-flex-col lg:tw-flex-row tw-justify-center tw-items-center lg:tw-space-x-2 lg:tw-space-y-0 tw-space-y-2'>
                    <Password placeholder='Nueva Contraseña' toggleMask :feedback="false" v-model="pass" />
                    <Password placeholder='Repita la Contraseña' toggleMask :feedback="false" v-model="passRepeat" />
                </div>
                <span class='tw-text-sm tw-text-red-500'>{{error}}</span>
                
                
            </div>

            <template #footer>
                <Button v-if='isCodeValid' label='Guardar' class='p-button-primary tw-px-8' @click='cambioDePass'/>
                <Button v-else :disabled='codigoDeRecuperacion.length < 5' label='Aceptar' class='p-button-primary tw-px-8' @click="handleEnviarCodigo"/>
                <Button label='Salir' class='p-button-primary p-button-link tw-px-8' @click="handlePassRecoverClick(false)"/>
            </template>

    </Dialog>

    <Dialog v-model:visible="showSolicitudExitosa" :modal="true" :style='{"max-width":"100vw"}'>
        <template #header>
            <div class='tw-w-full'>
            </div>  
        </template>

        
        <div class="tw-flex tw-flex-col tw-gap-3">
            <div class='tw-w-full'>
                <span class='tw-font-semibold tw-text-xl'>Solicitud Exitosa</span>
            </div>
            <hr>
            <div class='px-4 tw-flex tw-flex-col tw-gap-4 tw-text-justify tw-max-w-lg'>
                <p>Sus datos quedaron registrados, recibira un correo electronico informando el momento para poder comenzar a operar.</p>
                <span>Muchas Gracias.</span>
            </div>
        </div>

        <template #footer>
            <Button label='Aceptar' class='p-button-primary tw-px-8' @click='showSolicitudExitosa = false'/>
        </template>
    </Dialog>

</template>

<script lang="ts">


import { defineComponent, ref, onBeforeMount} from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex';
import { useToast } from "primevue/usetoast";
import useBackground from '@/hooks/useBackground';
import useLogo from '@/hooks/useLogo';
import axios from '@/plugins/axios';
import moment from 'moment';
import { AxiosError } from 'axios';

export default defineComponent({
    components:{
    },
    setup(){
        const toast = useToast();
        const store = useStore();
        const isLoad = ref(false);
        const animated = ref(false);
        const router = useRouter();
        const route = useRoute();
        const logo = useLogo();
        const BackgroundImage = useBackground()


        const tiposDNI = ref(['DNI','CI','Pasaporte'])
        const pass = ref('');
        const passRepeat = ref('');
        const user = ref('');
        const tipoDni = ref('DNI');
        
        const error = ref('');
        const ModalDisplay = ref(false);
        const ModalPassRecover = ref(false);
        const emailDeRecuperacion = ref('');
        const codigoDeRecuperacion = ref('');
        const isCodeValid = ref(false);
        
        const isModoRegistro = ref(false);
        const isModoEsperarCodigo = ref(false);
        const codigoValidacion = ref();
        const nuevoUserDocumento = ref('');
        const nuevoUserFechaNacimiento = ref('');
        const nuevoUserNombre = ref('');
        const nuevoUserApellido = ref('');
        const nuevoUserSexo = ref('');
        const nuevoUserEmail = ref('');
        const nuevoUserDireccion = ref('');
        const nuevoUserTelefono = ref('');
        const nuevoUserCarnetCobertura = ref('');
        const nuevoUserCobertura = ref();
        const nuevoUserPlan = ref();
        const nuevoUserCondicionIva = ref();
        const planesCoberturas = ref();
        const planesCondicionIva = ref();
        const coberturas = ref();
        const errorNuevoUsuario = ref('');
        const showSolicitudExitosa = ref(false);


        //time counter
        const time = ref();
        const timer = ref();
        
        const startInterval = () => {
            time.value = moment.duration(1,'minutes');
            timer.value = setInterval(() => {
                time.value = time.value.subtract(1,'seconds');
                if(time.value.asSeconds() <= 0){
                    clearInterval(timer.value);
                }
            },1000);
        }

        const loguear = async () => {
            try{
                const body = {
                    idcliente:store.state.configuracionPortal.idcliente,
                    tipodocumento:tipoDni.value,
                    numerodocumento:user.value,
                    password:pass.value,
                }
                const token = localStorage.getItem('tempToken');
                let result;

                if(token){
                    result = await axios.post('pacientes/loguear-portal',{
                        tempToken:token
                    });
                }else{
                    result = await axios.post('pacientes/loguear-portal',body);
                }

                result.data.animate = !token;
                result.data.embed = !!token;

                if(result.data.estado){
                    return result.data
                }else{
                    throw new Error('bloqueado');
                }

            }catch(err:AxiosError | any){
                throw new Error(err);
            } finally {
                localStorage.removeItem('tempToken');
            }
        }

        const handleClick = () => {
            isLoad.value = true;

            loguear()
            .then((datos) => {
                animated.value = true;

                setTimeout(()=> {
                    const newLog = {
                        idusuario: store.state.configuracionPortal.cliente.usuarios[0].id,
                        idcliente: store.state.configuracionPortal.idcliente,
                        descripcion: ""
                    }
                    //Obtengo ip del usuario
                    fetch('https://api.ipify.org/?format=json')
                    .then(( response:any) => response.json())
                    .then(( response:any ) => {
                        newLog.descripcion =  `El paciente ${datos.nombre} - ${datos.numerodocumento} ingreso al sistema con la IP ${response?.ip}`
                        axios.post('gmed-logs/save', newLog)
                        .catch(err =>{
                            console.log(err,"Error al crear log de ingreso")
                        })
                        
                    })
                    //En caso de que la Api no funcione, cargo el log sin la IP
                    .catch(() =>{
                        newLog.descripcion =  `El paciente ${datos.nombre} - ${datos.numerodocumento} ingreso al sistema`
                        axios.post('gmed-logs/save', newLog)
                        .catch(err =>{
                            console.log(err,"Error al crear log de ingreso")
                        })
                    })


                    const { cliente } = route.params
                    store.dispatch('autenticar',datos);
                    store.dispatch('setEmbed',datos.embed);
                    
                    datos.fechanacimiento.split('-')[0] === pass.value ?
                    router.push(`/${cliente}/cambio-pass`):
                    router.push(`/${cliente}/`)                    
                    
                },datos.animate ? 500 : 0)
            })
            .catch((err) =>{
                if(err.message == 'Error: bloqueado'){
                    toast.add({severity:'error',summary:'Advertencia',detail:'No tiene permitido el ingreso al Portal',life:2000})
                }else{
                    error.value = 'Usuario y/o contraseña incorrecta'
                }
            })
            .finally(()=>{
                isLoad.value = false;
            })
        }

        const handlePassRecoverClick = async (bool:boolean) => {
            
            if(!bool){
                clearInterval(timer.value);
                ModalPassRecover.value = false;
                error.value = '';
                isCodeValid.value = false;
                codigoDeRecuperacion.value = '';
                return;
            }
            
            
            if(user.value === ''){
                error.value = 'Ingrese su numero de documento';
                const el = (document.querySelector(".p-inputtext.p-component") as HTMLElement );
                el.focus();
                (document.querySelector(".user-group") as HTMLElement ).classList.add('invalid');
                return;
            }

            isLoad.value = true;


            try {
                const { data } = await axios.post('pacientes/recuperacion-password',{
                    idcliente:store.state.configuracionPortal.idcliente,
                    tipodocumento:tipoDni.value,
                    numerodocumento:user.value,
                })


                emailDeRecuperacion.value = data.email.split('@')[0].replace(/.(?=.{4,}$)/g, '*') + '@' + data.email.split('@')[1];
                


                error.value = ''
                ModalPassRecover.value = bool;
                timer.value = null;
                startInterval();

            } catch (err:AxiosError | any) {
                error.value = `${err.response.data}`;
            }finally{
                isLoad.value = false;
            }


            
        }
        const handleChangeUser = () => {
            (document.querySelector(".user-group") as HTMLElement ).classList.remove('invalid');
        }
        
        const handleNuevoUsuarioClick = (bool:boolean) => {

            ModalDisplay.value = bool;
            if(!bool){
                limpiarCampos();
            }
        }

        const handleEnviarCodigo = async() => {
            try {
                if(codigoDeRecuperacion.value.length < 5){
                    error.value = 'Ingrese un codigo valido'
                    return;
                }


                await axios.post('/pacientes/recuperacion-password-comprobacion',{
                    idcliente:store.state.configuracionPortal.idcliente,
                    tipodocumento:tipoDni.value,
                    numerodocumento:user.value,
                    codigoRecuperacion:codigoDeRecuperacion.value,
                })

                isCodeValid.value = true;
                pass.value = '';
                passRepeat.value = '';

            } catch (err:AxiosError | any) {
                error.value = `${err.response.data}`;
                ModalPassRecover.value = false;
            }
        }

        const cambioDePass = async () => {
            try {
                if(pass.value !== passRepeat.value){
                    error.value = 'Las contraseñas no coinciden'
                    return;
                }
                if(pass.value.length < 8){
                    error.value = 'La contraseña debe tener al menos 8 caracteres'
                    return;
                }
                
                
                await axios.post('/pacientes/recuperacion-password-cambio',{
                    idcliente:store.state.configuracionPortal.idcliente,
                    tipodocumento:tipoDni.value,
                    numerodocumento:user.value,
                    password:pass.value,
                })

                ModalPassRecover.value = false;
                error.value = '';
                isCodeValid.value = false;
                codigoDeRecuperacion.value = '';

                toast.add({severity:'success',summary:'Exito',detail:'Se ha actualizado la contraseña correctamente.',life:1500})
                
            } catch (err:AxiosError | any) {
                error.value = `${err.response.data}`;
            }

        }


        const obtenerCoberturas = async () => {
            try {
                const {idcliente} = store.state.configuracionPortal;


                const result = await axios.get(`pacientes/obtener-coberturas/${idcliente}`);


                coberturas.value = result.data;
            } catch (err) {
                console.log(err);
            }
        }
        
        const obtenerPlanes = async (idcobertura:number) => {
            try{
                const result = await axios.get(`/pacientes/obtener-plan-cobertura/${idcobertura}`);
                planesCoberturas.value = result.data;
            }catch(error){
                console.log(error);
            }
        }

        const onCoberturaChanged = (event:any) => {
            planesCoberturas.value = [];
            planesCondicionIva.value = [];
            nuevoUserPlan.value = null;
            nuevoUserCondicionIva.value = null;
            nuevoUserCarnetCobertura.value = '';
            obtenerPlanes(event.value.id);
        }

        const onPlanesChanged = (event:any) => {
            const { v_o } = event.value; 
            

            if(v_o === 'A'){
                planesCondicionIva.value = [{label:'Gravado',value:'G'}, {label:'Exento',value:'NG'}];
            }


            if(v_o !== 'A'){
                planesCondicionIva.value = [ v_o === 'G' ?{label:'Gravado',value:'G'} : {label:'Exento',value:'NG'}];
                nuevoUserCondicionIva.value = v_o === 'G' ? {label:'Gravado',value:'G'} : {label:'Exento',value:'NG'};
            }

            

        }

        const validarCampos = async () =>{
            let valido = true;
            
            const dniInValido = await validarDNI();
            
            valido = valido && nuevoUserDocumento.value !== '';
            debugger;
            if(valido && dniInValido){
                return false;
            }

            valido = valido && nuevoUserApellido.value !== '';
            valido = valido && nuevoUserNombre.value !== '';
            valido = valido && nuevoUserEmail.value !== '';
            valido = valido && nuevoUserSexo.value !== '';
            
            valido = valido && nuevoUserFechaNacimiento.value !== '';
            valido = valido && nuevoUserCobertura.value !== null && nuevoUserCobertura.value?.nombre !== '';
            
             
            if(nuevoUserCobertura.value?.carnetobligatorio){
                valido = valido && nuevoUserCarnetCobertura.value !== '';
                valido = valido && nuevoUserPlan.value !== null && nuevoUserPlan.value?.nombre !== '';
            }


            errorNuevoUsuario.value = valido ? '' : 'Complete todos los campos';

            


            return valido;
        }

        const validarDNI = async () => {
            try {
                const {idcliente } = store.state.configuracionPortal;
                const result = await axios.post('/pacientes/validar-documento',{
                    tipodocumento:tipoDni.value,
                    numerodocumento:nuevoUserDocumento.value,
                    idcliente
                })
                
                errorNuevoUsuario.value = result.data.existe ? 'El numero de documento ya existe' : '';


                return result.data.existe;

            } catch (error) {
                    console.log(error);
                    return false;
            }
        }

        const onAceptarClick = async () => {
            if(!(await validarCampos())){
                return;
            }

            try {
                codigoValidacion.value = Math.floor(Math.random() * (999999 - 100000)) + 100000;
                const {idcliente } = store.state.configuracionPortal;
                await axios.post('/pacientes/enviar-codigo-verificacion',{
                    codigo:codigoValidacion.value,
                    idcliente,
                    nombre:`${nuevoUserNombre.value} ${nuevoUserApellido.value}`,
                    mail:nuevoUserEmail.value,
                })

                

                errorNuevoUsuario.value = '';
                isModoRegistro.value = false;
                if(!isModoEsperarCodigo.value){
                    isModoEsperarCodigo.value = true;
                }
                clearInterval(timer.value);
                startInterval();
                toast.add({severity:'success',summary:'Exito',detail:'Se ha enviado el codigo correctamente.',life:1500})
                
            } catch (error) {
                console.log(error);
            }
        }

        const onAceptarCodigoClick = async () => {
            if(codigoValidacion.value.length <= 5){
                errorNuevoUsuario.value = 'Ingrese un codigo valido'
                return;
            }

            if(codigoValidacion.value !== parseInt(codigoValidacion.value)){
                errorNuevoUsuario.value = 'El codigo ingresado no es valido'
                return;
            }

            enviarSolicitudNuevoPaciente();


            
        }


        const enviarSolicitudNuevoPaciente = async () => {
            try {
                const {idcliente } = store.state.configuracionPortal;
                await axios.post('/paciente/confirmar-nueva-solicitud',
                {
                    idcliente,
                    paciente:{
                        nombre:`${nuevoUserNombre.value} ${nuevoUserApellido.value}`.toUpperCase(),
                        dni:`${tipoDni.value} - ${nuevoUserDocumento.value}`,
                        fechanacimiento:moment(nuevoUserFechaNacimiento.value).format('DD/MM/YYYY'),
                        email:nuevoUserEmail.value,
                        telefono:nuevoUserTelefono.value,
                        sexo:nuevoUserSexo.value,
                        direccion:nuevoUserDireccion.value,
                        cobertura:nuevoUserCobertura.value.nombre,
                        condicionIva:nuevoUserCondicionIva?.value?.label || '',
                        plan:nuevoUserPlan.value?.nombre || '',
                        carnet:nuevoUserCarnetCobertura?.value || '',
                        idcobertura:nuevoUserCobertura.value.id,
                        idplancobertura:nuevoUserPlan.value?.id,
                    }
                })

                toast.add({severity:'success',summary:'Exito',detail:'Se ha enviado la solicitud correctamente.',life:1500})
                isModoEsperarCodigo.value = false;
                isModoRegistro.value = false;
                ModalDisplay.value = false;
                showSolicitudExitosa.value = true;
                limpiarCampos();
            } catch (error) {
                console.log(error);
                toast.add({severity:'error',summary:'Error',detail:'No se pudo enviar la solicitud.',life:1500})
                clearInterval(timer.value);
                
            }
        }

        const limpiarCampos = () => {
            nuevoUserDocumento.value = '';
            nuevoUserApellido.value = '';
            nuevoUserNombre.value = '';
            nuevoUserEmail.value = '';
            nuevoUserFechaNacimiento.value = '';
            nuevoUserTelefono.value = '';
            nuevoUserDireccion.value = '';
            nuevoUserCobertura.value = null;
            nuevoUserPlan.value = null;
            nuevoUserCarnetCobertura.value = '';
            nuevoUserCondicionIva.value = null;
            nuevoUserSexo.value = '';
            planesCondicionIva.value = null;
            errorNuevoUsuario.value = '';
            codigoDeRecuperacion.value = '';
            codigoValidacion.value = '';
            isModoEsperarCodigo.value = false;
            isModoRegistro.value = false;
            clearInterval(timer.value);
        }

        const comprobarToken = async () => {
            const token = localStorage.getItem('tempToken');
            if(!token) return;
            handleClick();
        }

        onBeforeMount(() => {
            obtenerCoberturas();
            comprobarToken();
        })


        return {
            animated,
            isLoad,
            BackgroundImage,
            ModalDisplay,
            time,
            ModalPassRecover,
            emailDeRecuperacion,
            codigoDeRecuperacion,
            isCodeValid,
            logo,
            pass,
            passRepeat,
            user, 
            error, 
            tipoDni, 
            tiposDNI, 
            handleClick,
            handleChangeUser,
            handlePassRecoverClick, 
            handleNuevoUsuarioClick,
            handleEnviarCodigo,
            cambioDePass,
            //NuevoUsuario
            isModoRegistro,
            isModoEsperarCodigo,
            nuevoUserDocumento,
            nuevoUserFechaNacimiento,
            nuevoUserNombre,
            nuevoUserApellido,
            nuevoUserSexo,
            nuevoUserEmail,
            nuevoUserDireccion,
            nuevoUserTelefono,
            nuevoUserCarnetCobertura,
            nuevoUserCobertura,
            nuevoUserPlan,
            nuevoUserCondicionIva,
            planesCoberturas,
            planesCondicionIva,
            coberturas,
            onCoberturaChanged,
            onPlanesChanged,
            errorNuevoUsuario,
            onAceptarClick,
            onAceptarCodigoClick,
            showSolicitudExitosa,
        }
    }
});
</script>

<style scoped>
  .login{
    background-repeat: no-repeat;
    background-size: cover;
  }
  .login .animacion{
      animation: salida-login .5s forwards linear;
  }

  .login .animate {
      animation: entrada-login .3s linear forwards;
  }
  .login .user-group.invalid{
      
      box-shadow: 0 0 2px 1px red;
  }
  .login .user-group.invalid input:focus{
      outline:none;
  }


  @keyframes entrada-login {
    0%{
        opacity: 0;
        transform: translateX(200%);
    }100%{
        opacity: 1;
        transform: translateX(0);
    }
  }
  @keyframes salida-login {
    0%{
        transform: translateX(0%);
        opacity: 1;
    }
    20%{
        transform:translateX(-10%)
    }
    100%{
        transform: translateX(200%);
        opacity: 0;
    }
  }

  .login .p-inputgroup > .p-inputwrapper:first-child, .dropdown{
      max-width:10ch;
  }
</style>